(function($) {
    function btmHgt() {
        var btmHgth1 = $('.hero-btm-sec h1').innerHeight() / 2;
        var btmHgt = $('.hero-btm-sec').innerHeight() / 2 + btmHgth1;
        $('.hero-btm-sec').css('margin-top', -btmHgt);

        if ($(window).width() > 991) {
            var ab = $('.advantage-row .advantage-img').innerHeight() + 200;
            $('.advantage-row .advantage-txt').css('min-height', ab);
        }
    }

    $(window).on("load", function() {
        btmHgt()
    });

    $(document).ready(function() {
        $('#nav-icon1').click(function() {
            $(this).toggleClass('open');
            $('.header-right').slideToggle();
        });

        $(".left-tabs-sec ul li").mouseover(function() {
            $(this).addClass('active');
            $(this).siblings('li').removeClass('active');
            var valtxt = $(this).attr('value');
            $('#' + valtxt).siblings().hide();
            $('#' + valtxt).fadeIn();
        });

        $('#parentHorizontalTab').easyResponsiveTabs({
            type: 'default', //Types: default, vertical, accordion
            width: 'auto', //auto or any width like 600px
            fit: true, // 100% fit in a container
            tabidentify: 'hor_1', // The tab groups identifier
        });

        $('.accordion-list > li:not(:first-child) > .answer').hide();

        $('.accordion-list > li > h3').click(function() {
            if ($(this).parent('li').hasClass("active")) {
                $(this).parent('li').removeClass("active").find(".answer").slideUp();
            } else {
                $(".accordion-list > li.active .answer").slideUp();
                $(".accordion-list > li.active").removeClass("active");
                $(this).parent('li').addClass("active").find(".answer").slideDown();
            }
            return false;
        });

        $('.accordin-in > li:not(:first-child) > .data-answer').hide();
        $('.accordin-in > li > h4').click(function() {
            if ($(this).parent('li').hasClass("active")) {
                $(this).parent('li').removeClass("active").find(".data-answer").slideUp();
            } else {
                $(".accordin-in > li.active .data-answer").slideUp();
                $(".accordin-in > li.active").removeClass("active");
                $(this).parent('li').addClass("active").find(".data-answer").slideDown();
            }
            return false;
        });
        $('.filter-option .filter-btn').click(function(e) {
				 	e.preventDefault()
          $($(this).attr('href')).fadeIn();
        });
         $('.filter-popup-wrap .close').click(function() {
            $('.filter-popup-wrap').fadeOut();
        });
    });

    //parallax scroll
    $(window).on("load scroll", function() {
        if ($(window).width() > 991) {
            var parallaxElement = $(".parallax_scroll"),
                parallaxQuantity = parallaxElement.length;

            window.requestAnimationFrame(function() {
                for (var i = 0; i < parallaxQuantity; i++) {
                    var currentElement = parallaxElement.eq(i),
                        windowTop = $(window).scrollTop(),
                        elementTop = currentElement.offset().top,
                        elementHeight = currentElement.height(),
                        viewPortHeight = window.innerHeight * .75 - elementHeight * 0.75,
                        scrolled = windowTop - elementTop + viewPortHeight;
                    currentElement.css({
                        transform: "translate3d(0," + scrolled * 0.5 + "px, 0)"
                    });
                }
            });
        }
    });
    $(window).scroll(function() {
        if ($(this).scrollTop() > 60) {
            $('.header').addClass("sticky");
        } else {
            $('.header').removeClass("sticky");
        }
    });
    $(window).resize(function() {
        btmHgt();

    });

})(jQuery);



//////////////////dropdown on select
var x, i, j, l, ll, selElmnt, a, b, c;
/*look for any elements with the class "custom-select":*/
x = document.getElementsByClassName("custom-select");
l = x.length;
for (i = 0; i < l; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  ll = selElmnt.length;
  /*for each element, create a new DIV that will act as the selected item:*/
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  /*for each element, create a new DIV that will contain the option list:*/
  b = document.createElement("DIV");
  b.setAttribute("class", "select-items select-hide");
  for (j = 1; j < ll; j++) {
    /*for each option in the original select element,
    create a new DIV that will act as an option item:*/
    c = document.createElement("DIV");
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.addEventListener("click", function(e) {
        /*when an item is clicked, update the original select box,
        and the selected item:*/
        var y, i, k, s, h, sl, yl;
        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
        sl = s.length;
        h = this.parentNode.previousSibling;
        for (i = 0; i < sl; i++) {
          if (s.options[i].innerHTML == this.innerHTML) {
            s.selectedIndex = i;
            h.innerHTML = this.innerHTML;
            y = this.parentNode.getElementsByClassName("same-as-selected");
            yl = y.length;
            for (k = 0; k < yl; k++) {
              y[k].removeAttribute("class");
            }
            this.setAttribute("class", "same-as-selected");
            break;
          }
        }
        h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener("click", function(e) {
      /*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
      e.stopPropagation();
      closeAllSelect(this);
      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
}
function closeAllSelect(elmnt) {
  /*a function that will close all select boxes in the document,
  except the current select box:*/
  var x, y, i, xl, yl, arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}
/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
document.addEventListener("click", closeAllSelect);

// document.getElementById("Courriel").addEventListener("click",  emailvalidate);
// function emailvalidate(){
//     var regemail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
//     var email = document.getElementById('Courriel').value;
	
//     if(!regemail.test(email)){
//         document.getElementById('error-email').innerHTML="Saisissez une adresse e-mail, ex. exemple@monsite.com. ";
		
//     }else{
//         document.getElementById('error-email').innerHTML="";
//     } 
	
//  }
//  document.getElementById("Courriel").focus({ focusVisible: false });

  // Function to validate email on input
  function validateEmail() {
	// Get the input value
	var emailInput = document.getElementById('emailInput').value.trim();

	// Regular expression for a simple email validation
	var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	// Check if the email matches the regular expression
	if (emailRegex.test(emailInput)) {
		document.getElementById('validationResult').textContent = '';
	} else {
		document.getElementById('validationResult').textContent = 'Saisissez une adresse e-mail, ex. exemple@monsite.com.';
	}
	var element = document.getElementById('myElement');

            // Check the current display property
            if (element.style.display === 'none') {
                // If currently hidden, display it
                element.style.display = 'block';
            } else {
                // If currently visible, hide it
                element.style.display = 'none';
            }
}

 // Function to validate email on input
  function validateEmail() {
	// Get the input value
	var emailInput = document.getElementById('emailInput').value.trim();

	// Regular expression for a simple email validation
	var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	// Check if the email matches the regular expression
	if (emailRegex.test(emailInput)) {
		document.getElementById('validationResult').textContent = '';
		var element = document.getElementById('myElement');
		element.style.display = 'none';
	} else {
		document.getElementById('validationResult').textContent = 'Saisissez une adresse e-mail, ex. exemple@monsite.com.';
		var element = document.getElementById('myElement');
		element.style.display = 'inline-block';
	}
}

// Attach the validateEmail function to the input event
document.getElementById('emailInput').addEventListener('input', validateEmail);

//////////////////////validate message
document.getElementById('message').addEventListener('input', function() {
	var inputValue = this.value.trim();
	var validationMessageElement = document.getElementById('msg-error');

	if (inputValue === '') {
		validationMessageElement.textContent = 'Saisissez une réponse';
		var element = document.getElementById('myElement_2');
		element.style.display = 'inline-block';

	} else {
		validationMessageElement.textContent = ''; // Clear validation message
		var element = document.getElementById('myElement_2');
		element.style.display = 'none';

	}
});


document.getElementById('submitButton').addEventListener('click', validateFields);

function validateFields() {
	var inputField1Value = document.getElementById('emailInput').value.trim();
	var inputField2Value = document.getElementById('message').value.trim();
	var validationMessageElement_1 = document.getElementById('validationResult');
	var validationMessageElement_2 = document.getElementById('msg-error');

	if (inputField1Value === '' || inputField2Value === '') {
		validationMessageElement_1.textContent = 'Saisissez une adresse e-mail, ex. exemple@monsite.com.';
		var element = document.getElementById('myElement');
		element.style.display = 'inline-block';
		validationMessageElement_2.textContent = 'Saisissez une réponse';
		var element = document.getElementById('myElement_2');
		element.style.display = 'inline-block';
	} else {
		validationMessageElement_1.textContent = ''; 
		var element = document.getElementById('myElement');
		element.style.display = 'none';
		validationMessageElement_2.textContent = ''; 
		var element = document.getElementById('myElement_2');
		element.style.display = 'none';

		// Clear validation message
		// Proceed with your logic or form submission here
	}
}

